import React, { ReactElement, FC, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import api from "../../api";

const GoogleLoginButton: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const handleGoogleLogin = async (token: string) => {
    try {
      const res = await api.auth.google(token);
      if (res.success) {
        const { data } = res;
        console.log("Login Success:", data);
        
        navigate('/about', { replace: true });
      }
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  return <GoogleLogin
    onSuccess={(credentialResponse) => {
      if (credentialResponse.credential) {
        handleGoogleLogin(credentialResponse.credential);
      }
    }}
    onError={() => {
      console.log('Login Failed');
    }}
  />;
}

export default GoogleLoginButton;
