import { Box, Button, Card, CardMedia, Container, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import api, { Gym } from '../api';
import LogoLoading from '../icons/logoLoading';

const ExpressDayTraining: React.FC = () => {
  const { gymId } = useParams<{ gymId: string }>();
  const [document, setDocument] = useState<string>('');
  const [gym, setGym] = useState<Gym | null>();
  const [clientRequest, setClientRequest] = useState<any>({ data: null, status: 'initial', error: null });
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocument(event.target.value);
  };
  const fetchGym = async (gymId: string) => {
    const response = await api.gyms.get(parseInt(gymId));
    if (response.success) {
      setGym(response.data);
    } else {
      // window.location.href = '/';
    }
  };
  const getClientByDocument = async (document: string) => {
    setClientRequest({ ...clientRequest, status: 'loading' });
    const response = await api.clients.getByDocument(document);
    if (response.success) {
      setClientRequest({ data: response.data, status: 'success', error: null });
    } else {
      setClientRequest({ ...clientRequest, status: 'error', error: response.error });
    }
  }
  const handleClickDocument = () => {
    if (!document) return;
    getClientByDocument(document);
  }

  useEffect(() => { 
    if (!gymId) return;
    fetchGym(gymId);
  }, [gymId]);
  
  if (!gym) {
    return <LogoLoading />;
  };
  return (
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3} sx={{ marginTop: 4 }}>
          {!clientRequest.data && (
            <Box display="flex" flexDirection="column" p={4}>
              <CardMedia
                component="img"
                loading="lazy"
                height="150"
                image={gym?.logoUrl || '/logo_2_out.png'}
                sx={{ objectFit: 'contain' }}
              />
              <Typography variant="h3">{t('ExpressDayTraining.title', { gym: gym?.name })}</Typography>
              <Typography variant='h5'>{t('ExpressDayTraining.subtitle')}</Typography>
              <TextField
                label={t('ExpressDayTraining.document')}
                type="number"
                value={document}
                onChange={handleInputChange}
                variant="outlined"
              />
              <Button variant="contained" color="primary" sx={{ marginTop: 2 }} disabled={clientRequest.status === 'loading'} onClick={handleClickDocument}>
                {t('ExpressDayTraining.start')}
              </Button>
            </Box>
          )}
          {clientRequest.data && (
            <Box display="flex" flexDirection="column" p={4}>
              <CardMedia
                component="img"
                loading="lazy"
                height="150"
                image={gym?.logoUrl || '/logo_2_out.png'}
                sx={{ objectFit: 'contain' }}
              />
              {clientRequest.data.gender === 'MASCULINE' && <Typography variant="h3">{t('ExpressDayTraining.welcomeClient', { name: clientRequest.data.name })}</Typography>}
              {clientRequest.data.gender !== 'MASCULINE' && <Typography variant="h3">{t('ExpressDayTraining.welcomeClienta', { name: clientRequest.data.name })}</Typography>}
              <Typography variant='h5'>{t('ExpressDayTraining.howThisWorks')}</Typography>
            </Box>
          )}
        </Paper>
    </Container>
  );
};

export default ExpressDayTraining;
